import React, { useEffect, useState } from 'react';
import { PaymentElement, AddressElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import { getFirestore, doc, setDoc, getDoc, serverTimestamp } from 'firebase/firestore';
import './Checkout.css';
import { Modal, Button } from 'react-bootstrap';
import { Icon } from '@iconify/react';
import { random } from 'gsap';

const CheckoutForm = ({ clientSecret, subscriptionId, stripeId, formattedNextChargeDate, formattedNextChargeYear, formattedNextShipmentDate, paymentIntentId }) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const location = useLocation();
  const { bundle, finalPriceRaw, annualPriceRaw, frequency, product, oneTime } = location.state || {};
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [succeeded, setSucceeded] = useState(false);
  const [user, setUser] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const db = getFirestore();

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        const userDoc = await getDoc(doc(db, 'customers', currentUser.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          // Check if the user has an active bundle
          const hasActiveBundle = userData.bundle && userData.bundle.canceled === false;
          // Check if the user has an active subscription to the specific product
          const hasActiveProductSubscription = product && userData.products && userData.products[product.product_id] && userData.products[product.product_id].canceled === false;
          
          if (bundle && hasActiveBundle && !oneTime) {
            setModalIsOpen(true);
          } else if (product && hasActiveProductSubscription) {
            setModalIsOpen(true);
          }
        }
      }
    });
  }, [db, bundle, product]);

  const closeModal = () => {
    setModalIsOpen(false);
    navigate('/'); // Redirect user if they can't proceed with the checkout
  };

  const finalPrice = Number(finalPriceRaw);
  const annualPrice = Number(annualPriceRaw);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);

    const addressElement = elements.getElement(AddressElement);
    const address = await addressElement.getValue();

    const payload = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.location.origin,
      },
      redirect: 'if_required'
    });

    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
    } else {
      try {
        const userId = user.uid;
        const docId = subscriptionId ? subscriptionId : paymentIntentId;

        // Write to the 'orders' collection
        await setDoc(doc(db, 'orders', docId), {
          customerId: userId,
          stripeId: stripeId,
          intent: subscriptionId || paymentIntentId,
          paymentStatus: 'succeeded',
          shippingAddress: address.value,
          email: user.email,
          createdAt: serverTimestamp(),
        });

        if (bundle) {
          if (oneTime) {
            const randomId = `${bundle.id}${Math.floor(Math.random() * 9000 + 1000)}`;
            
            await setDoc(doc(db, 'customers', userId), {
              oneTime: {[randomId] :{
                bundleName: bundle.name,
                bundlePrice: (bundle?.price * 2) + 10,
                nextChargeDate: serverTimestamp(),
                nextShipmentDate: serverTimestamp(),
                address: address.value,
                paymentStatus: 'succeeded',
                dateOfPurchase: serverTimestamp(),
                email: user.email,
                stripeId: stripeId,
                shipmentStatus: 'not shipped',
                paymentIntentId: docId,
                pastChargeDates: [],
                pastShipments: {},
                canceled: false,
                bundleId: bundle.id
              },
              name: user.displayName
            }}, { merge: true });
            }
          else {
            await setDoc(doc(db, 'customers', userId), {
              bundle: {
                bundleName: bundle.name,
                bundlePrice: finalPrice,
                nextChargeDate: serverTimestamp(),
                nextShipmentDate: serverTimestamp(),
                address: address.value,
                paymentStatus: 'succeeded',
                frequency: frequency,
                dateOfPurchase: serverTimestamp(),
                email: user.email,
                stripeId: stripeId,
                shipmentStatus: 'not shipped',
                subscriptionId: subscriptionId,
                pastChargeDates: [],
                pastShipments: {},
                canceled: false,
                bundleId: bundle.id
              },
              name: user.displayName
            }, { merge: true });
            }
        }

        
        if (product) {
          await setDoc(doc(db, 'customers', userId), {
            products: {
              [product.safe_product_id]: {
                productName: product.product_id,
                productPrice: finalPrice,
                nextChargeDate: serverTimestamp(),
                nextShipmentDate: serverTimestamp(),
                address: address.value,
                paymentStatus: 'succeeded',
                frequency: frequency,
                dateOfPurchase: serverTimestamp(),
                email: user.email,
                stripeId: stripeId,
                shipmentStatus: 'not shipped',
                subscriptionId: subscriptionId,
                pastChargeDates: [],
                pastShipments: {},
                canceled: false
              }
            },
            name: user.displayName
          }, { merge: true });
        }

        setError(null);
        setProcessing(false);
        setSucceeded(true);

        navigate('/OrderPlaced', { state: { bundleName: bundle ? bundle.name : product.name } });
      } catch (firestoreError) {
        console.error('Error writing to Firestore:', firestoreError);
        setError(`Failed to write to Firestore: ${firestoreError.message}`);
        setProcessing(false);
      }
    }
  };

  // console.log(product.safe_product_id)

  return (
    <>
      <Modal show={modalIsOpen} onHide={closeModal} centered>
        <div className="SignIn-Modal">
          <div className="active-already-modal">
            <div className='SignIn-Header-Title'>Apologies!</div>
            <div className='SignIn-SubHeader-Title'>Subscription Already Active</div>
            <div style={{color: 'black'}}>
              {bundle ? (
                <p>If you want to purchase another bundle on the same account, you need to cancel your previous one or purchase one on a different account! We're in the process of making it so you can get another bundle on the same account so please stay patient. Thank you!</p>
              ) : (
                <p>You already have an active subscription for this product. If you want to purchase another one, you need to cancel your previous subscription or purchase it on a different account. Thank you!</p>
              )}
            </div>
            <Button variant="secondary" onClick={closeModal} className="submit-btn" style={{ backgroundColor: "black" }}>
              Close
            </Button>
          </div>
        </div>
      </Modal>
      <form onSubmit={handleSubmit}>
        <PaymentElement />
        <AddressElement options={{ mode: 'shipping' }} />
        <div className="payment-button-wrapper">
          <button className="payment-button" disabled={processing || !stripe || !elements || modalIsOpen}>
            {processing ? 'Processing...' : 'Subscription Powered by Stripe'}
          </button>
          {error && <div className="error-message">{error}</div>}
          {succeeded && <div className="success-message">Payment Succeeded!</div>}
        </div>
      </form>
    </>
  );
};

export default CheckoutForm;
