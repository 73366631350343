import ActivitySection from "../../component/ActivitySection/ActivitySection";
import FeedBackSection from "../../component/FeedbackSection/FeedBackSection";
import Footer from "../../component/Footer/Footer";
import HeroSection from "../../component/HeroSection/HeroSection";
import IntroductionSection from "../../component/IntroductionSection/IntroductionSection";
import OfferSection from "../../component/OfferSection/OfferSection";
import OfferSectionTwo from "../../component/OfferSection/OfferSectionTwo";
import React, { useEffect } from 'react';
import './MainPage.css';


function MainPage() {
    useEffect(() => {   
        const originalBackgroundColor = document.body.style.backgroundColor;
     
        document.body.style.backgroundColor = 'white';
       
        return () => {
          document.body.style.backgroundColor = originalBackgroundColor;
        };
      }, [])

    return ( <>
    <div className="sections-wrapper"> 
    <HeroSection/>
    <IntroductionSection/>
    <ActivitySection/>
    </div>
    {/* <OfferSectionTwo/> */}
    {/* <OfferSection/> */}
    <FeedBackSection/>
    <Footer/>
    </> );
}

export default MainPage;