import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import './LearnMore.css';
import { introcard } from "../../images";


function LearnMore({ handleClose }) {

    return (
        <>
        <div className="intro-card">
            <img src={introcard} alt="" />
        </div>
        <div className="top-button"> the v1 beta 🎉 </div>
        <div className="learnmore-popup">
            <div className="learn-more-header"> Introducing the dotScore: </div>
            <div className="learn-more-body"> Reviews say a lot. So, we’ve studied over 2 million of them to give you insights into how different products affect your skin. </div>
            <Link className="btn btn-black btn-default" to="/" onClick={handleClose}>Get Started</Link>
            <Link to="/mainpage" className="forgot-password-normal" onClick={handleClose}> or Learn More</Link>
        </div>    
        </>
    );
}

export default LearnMore;