import React, { useEffect } from "react";
import { mask, phone, halfiphone } from "../../images";
import "./HeroSection.css";
import {Link }from "react-router-dom";
import { Icon } from "@iconify/react";


function HeroSection() {
  useEffect(() => {
    const handleScroll = () => {
      const phoneImage = document.querySelector(".phone");
      const scrollValue = window.scrollY;
      phoneImage.style.transform = `translateY(${-scrollValue * 0.02}px)`;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <section className="hero-section">
      <div className="mobile-container1">
        <div className="hero-section-card">
          <img src={mask} alt="" className="mask" />
          <img src={phone} alt="" className="phone" />
          <div className="version">
            <span> the v1 beta 🎉</span>
          </div>
          <h1 className="banner-text">
            <Link to="/mainpage" className="highlight-2">you can shop</Link>
            <span className="strikethrough">  like last time:
            scattered, lost, not sure what to search, watch or trust, or you can
            shop </span> <Link to="/mainpage" className="highlight-2">on shopdot </Link>
          </h1>
          <Link to="/" className="search">
              <Icon icon="mingcute:check-circle-line" className="icon-left" />
              <div className="cta-text">
                  <div className="search-text-top">Get Started</div>
                  <div className="search-text-bottom">Shop smart. You'll love it</div>
              </div>
              <Icon icon="ph:caret-circle-right-fill" className="icon-right" />
          </Link>
          <img src={halfiphone} alt="" className="halfiphone" />
        </div>
      </div>
    </section>
  );
}

export default HeroSection;
