import React, { useEffect } from "react";
import { frame1, frame2, pricecard } from "../../images";
import "./ActivitySection.css";
import { Link } from "react-router-dom";

function ActivitySection() {
  useEffect(() => { }, []);
  return (
    <section className="activity-section">
      <div className="mobile-container1">
        <div className="activity-images">
          <div className="activity-image frame1">
            <img src={frame1} alt="" />
            <img src={frame1} alt="" style={{ marginTop: '10px'}} />
          </div>
          <div className="activity-image frame2">
            <img src={frame2} alt="" />
            <img src={frame2} alt="" style={{ marginTop: '10px' }} />
          </div>
        </div>
        <div className="activity-card">
          <div className="content flex-column">
            <div className="headings flex-column">
              <h6 className="heading">on dot</h6>
              <h4 className="sub-heading">
                Cut through the clutter.
              </h4>
            </div>
            <p className="text">
              What everyone’s talking about 🤝 what you need to know. Now with expert input on concerns, highlights & more.
            </p>
          </div>
          <Link to="/mainpage" className="btn btn-black btn-skin btn" style={{ boxShadow: 'none' }}>See Activity</Link>
        </div>
        <div className="mobile-image-slider">
          <img src={pricecard} alt="" className="price-card" />
          <img src={pricecard} alt="" className="price-card" />
          <img src={pricecard} alt="" className="price-card" />
          <img src={pricecard} alt="" className="price-card" />
        </div>
      </div>
    </section>
  );
}

export default ActivitySection;
