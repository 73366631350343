import { useEffect, useRef, useState } from "react";
import { mask, fulliphone } from "../../images";
import "./OfferSectionTwo.css";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

function OfferSectionTwo() {
  const [activeBox, setActiveBox] = useState(1);
  const offerSectionRef = useRef();

  useEffect(() => {

    gsap.to(".offertwo", {
        scrollTrigger: {
          trigger: ".offer-section-two",
          pin: ".offer-section-two",
          start: "top top",
          end: "+=4000",
          scrub: 1,
        },
      });

    const handleScroll = () => {
      const offerSectionTop =
        offerSectionRef.current.getBoundingClientRect().top;
      const scrollPosition = window.scrollY - offerSectionTop;
      const boxIndex = Math.floor(scrollPosition / 1700);

      setActiveBox(boxIndex);
  
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section className="offer-section-two"  ref={offerSectionRef}>
      <div className="mobile-container1">
        <div className="pinned-content d-flex mx-auto">
        <div className="offertwo">
          <div
            className={`offer-two-card first ${
              activeBox === 1 ? "active-box" : "inactive-box"
            }`}
          >
            <img src={mask} alt="" className="mask" />
            <h6 className="heading">more, on dot</h6>
            <h4 className="sub-heading">
              This moisturizer won’t break out my oily skin, right?
            </h4>
            <img src={fulliphone} alt="" />
          </div>
          <div
            className={`offer-two-card second ${
              activeBox === 2 ? "active-box" : "inactive-box"
            }`}
          >
            <img src={mask} alt="" className="mask" />
            <h6 className="heading">more, on dot</h6>
            <h4 className="sub-heading">
              What are dermatologists saying about this?
            </h4>
            <img src={fulliphone} alt="" />
          </div>
          <div
            className={`offer-two-card third ${
              activeBox === 3 ? "active-box" : "inactive-box"
            }`}
          >
            <img src={mask} alt="" className="mask" />
            <h6 className="heading">more, on dot</h6>
            <h4 className="sub-heading">What’s in this stuff?</h4>
            <img src={fulliphone} alt="" />
          </div>

          <div
            className={`offer-two-card fourth ${
              activeBox === 4 ? "active-box" : "inactive-box"
            }`}
          >
            <img src={mask} alt="" className="mask" />
            <h6 className="heading">more, on dot</h6>
            <h4 className="sub-heading">Coming Soon</h4>
            <img src={fulliphone} alt="" />
          </div>
        </div>
      </div>
      </div>
    </section>
  );
}

export default OfferSectionTwo;
