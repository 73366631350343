import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import {
  banner1,
  banner2,
  dot,
  reviewcard,
  scorecard,
  scorecard2,
} from "../../images";
import "./IntroductionSection.css";
import { Power1 } from "gsap";
import { useEffect } from "react";
import { Link } from "react-router-dom";

gsap.registerPlugin(ScrollTrigger);
gsap.defaults({ ease: "none" });

function IntroductionSection() {
  useEffect(() => {
    const svgPath1 = document.querySelector(".theLine1");
    const svgPath2 = document.querySelector(".theLine2");

    const pathLength1 = svgPath1.getTotalLength();
    svgPath1.setAttribute(
      "style",
      "stroke-dasharray: " +
      pathLength1 +
      "; stroke-dashoffset: " +
      pathLength1 +
      ";"
    );

    const pathLength2 = svgPath2.getTotalLength();
    svgPath2.setAttribute(
      "style",
      "stroke-dasharray: " +
      pathLength2 +
      "; stroke-dashoffset: " +
      pathLength2 +
      ";"
    );

    gsap.to(svgPath1, {
      scrollTrigger: {
        trigger: ".svg-animationso",
        start: "top center",
        end: "bottom center",
        scrub: true,
      },
      strokeDashoffset: 0,
      duration: 1,
      ease: Power1.easeInOut,
    });

    gsap.to(svgPath2, {
      scrollTrigger: {
        trigger: ".svg-animationso",
        start: "top center",
        end: "bottom center",
        scrub: true,
      },
      strokeDashoffset: 0,
      duration: 1,
      ease: Power1.easeInOut,
    });
  }, []);
  return (
    <section className="introduction-section ">
      <div className="mobile-container1">
        <div className="introduction-card">
          <div className="content flex-column">
            <div className="headings flex-column">
              <h6 className="heading">Your next go-to?</h6>
              <h4 className="sub-heading">Introducing the dotScore:</h4>
              <h5 className="sub-sub-heading">
                shop smarter suggestions by skin type & preference.
              </h5>
            </div>

            <p className="text">
              Reviews say a lot. So, we’ve studied over 500,000 of them to give
              you insights into how different moisturizers affect your skin.
            </p>
          </div>
          <Link to="/scoreexplained" className="btn btn-gray btn-default" style={{ fontFamily: 'DM Sans, sans-serif' }}>Learn More</Link>
        </div>
        <div className="bubble-card-main">
          <div className="bubble-card d-flex mx-auto">
            <img src={scorecard2} alt="" />
          </div>
          <div className="bubble-card-two d-flex mx-auto">
            <img src={scorecard} alt="" />
          </div>
          <div className="bubble-card-three d-flex mx-auto">
            <img src={banner1} alt="" />
          </div>
          <div className="bubble-card-four d-flex mx-auto">
            <img src={banner2} alt="" />
          </div>
          <div className="bubble-card-five ">
            <img src={reviewcard} alt="" className="d-flex mx-auto" />
          </div>
        </div>
        <div className="svg-animationso-Main">
          <div className="svg-animationso">
            <svg
              width="937"
              height="883"
              viewBox="0 0 937 883"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M772 374V543.77C772 576.683 745.486 603.452 712.574 603.767L459.033 606.194H216C204.954 606.194 196 615.149 196 626.194V754"
                stroke="#000000"
                strokeWidth="3"
                strokeLinecap="round"
                className="theLine1"
              />
              <path
                d="M772 167V447.168C772 485.566 741.067 516.797 702.671 517.165L459.033 519.5H216C204.954 519.5 196 528.454 196 539.5V667.5"
                stroke="#000000"
                strokeWidth="3"
                strokeLinecap="round"
                className="theLine2"
              />
            </svg>
          </div>
          <div className="svg-animationso-default">
            <svg
              width="937"
              height="883"
              viewBox="0 0 937 883"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M772 374V543.77C772 576.683 745.486 603.452 712.574 603.767L459.033 606.194H216C204.954 606.194 196 615.149 196 626.194V754"
                stroke="#F0F0F0"
                strokeWidth="3"
                strokeLinecap="round"
                className="theLine1"
              />
              <path
                d="M772 167V447.168C772 485.566 741.067 516.797 702.671 517.165L459.033 519.5H216C204.954 519.5 196 528.454 196 539.5V667.5"
                stroke="#F0F0F0"
                strokeWidth="3"
                strokeLinecap="round"
                className="theLine2"
              />
            </svg>
          </div>
        </div>
      </div>
    </section>
  );
}

export default IntroductionSection;
