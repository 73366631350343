import "./FeedbackSection.css";
import { Link } from "react-router-dom";

function FeedBackSection() {
  return (
    <section className="feedback-section">
      <div className="mobile-container1">
        <div className="feedback-card">
          <div className="content flex-column">
            <div className="headings flex-column">
              <h6 className="heading">Thoughts?</h6>
              <h3 className="sub-heading">
                With your feedback, our beta gets better everyday.
              </h3>
            </div>
            <p className="text">
              We’ve got a lot in store & even more coming soon. Fill out this
              survey so we can get your feedback and even schedule a
              conversation!
            </p>
          </div>
          <Link to="https://calendly.com/d/5d7-sbg-2s6" className="btn btn-gray btn-default">Let’s chat!</Link>
        </div>
      </div>
    </section>
  );
}

export default FeedBackSection;