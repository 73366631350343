import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useState, useRef } from "react";
import { fulliphone, mask } from "../../images";
import "./OfferSection.css";

gsap.registerPlugin(ScrollTrigger);

function OfferSection() {
  const [activeBox, setActiveBox] = useState(0); // Start activeBox from 0
  const offerSectionRef = useRef(null);

  const boxTexts = [
    "This moisturizer won’t breakout my oily skin, right?",
    "What are dermatologists saying about this?",
    "What’s in this stuff?",
    "Coming soon.",
  ];

  useEffect(() => {
    gsap.to(".desktopContent", {
      scrollTrigger: {
        trigger: ".offer-card",
        pin: ".offer-section",
        start: "top top",
        end: "+=2800",
        scrub: 1,
      },
    });

    const updateActiveBox = () => {
      const offerSectionTop =
        offerSectionRef.current.getBoundingClientRect().top;
      const scrollPosition = window.scrollY - offerSectionTop;
      const boxIndex = Math.floor((scrollPosition - 2263) / 1000);

      setActiveBox(boxIndex);
    };

    window.addEventListener("scroll", updateActiveBox);

    return () => {
      window.removeEventListener("scroll", updateActiveBox);
    };
  }, []);

  const handleBoxClick = (index) => {
    setActiveBox(index);
  };

  return (
    <section className="offer-section" ref={offerSectionRef}>
      <div className="mobile-container1">
        <div className="pinned-content1">
          <div className="offer-card">
            <img src={mask} alt="" className="mask" />
            <div className="gallery">
              <div className="right">
                <div className="headings flex-column">
                  <h1 className="heading">more, on dot</h1>

                  {[0, 1, 2, 3].map((index) => (
                    <div
                      key={index}
                      className={`box ${
                        activeBox === index ? "active-box" : ""
                      }`}
                      onClick={() => handleBoxClick(index)}
                    >
                      <div className="text">{boxTexts[index]}</div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="left">
                <div className="desktopContent">
                  {[0, 1, 2, 3].map((index) => (
                    <div key={index} className="desktopContentSection">
                      {activeBox === index && <img src={fulliphone} alt="" />}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OfferSection;
