import { Link } from "react-router-dom";
import { dot } from "../../images";

import "./HeaderTwo.css";

function HeaderTwo() {
  return (
    <div className="header">
      <div className="mobile-container1 header-content">
        <div className="header-image">
          <Link to="/"><img src={dot}  alt="" /></Link>
        </div>
        <div className="header-items">
          <Link to="https://gasuiu50j6d.typeform.com/jointhebeta" className="header-item">for Creators</Link>
          <Link to="/" className="btn btn-black btn-">Shop</Link>
        </div>
      </div>
    </div>
  );
}

export default HeaderTwo;
